<template>
  <div class="content">
    <div class="slide1">
      <swiper :options="swiperOption" class="swiper">
        <swiper-slide :key="index" class="swiperSlide" v-for="(item,index) in swiperList">
          <a :href="item.link" target="_blank"><img :src="$url+item.image"   alt=""></a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <loading :empty="empty" :loading="loading"></loading>
    <div class="view">
      <div class="title">
<!--        <p>{{ result.title }}-->
<!--          <span>{{ result.updatetime | time }}</span>-->
<!--        </p>-->

      </div>

      <div class="tuwen">
        <p v-html="result.content"></p>
      </div>



    </div>
<!--    <div class="team" style="height:1vm;">-->
<!--      <img style="width:382px;height:77px;display: block;margin:0 auto;" src="../assets/dong/sj.png">-->
<!--    </div>-->
    <div class="demo-image" >
      <div class="block" v-for="fit in showimg" :key="fit">
        <el-image
            style="width: 21.5vw; margin-left:0.3vw;height: 280px"
            :src="$url+fit.image"
            fit="cover  "></el-image>
      </div>
    </div>

  </div>
</template>

<script>
import moment from "moment";
import loading from "../components/loading";

export default {
  name: "exchangeView",
  data() {
    return {
      result: {},
      loading: true,
      empty: false,
      type: this.$route.query.type,
      fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      showimg: [],
      swiperList:[],
      swiperOption: {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        autoplay: {
          delay: 5000,
        },

      },
    }
  },
  created() {
    this.fetchData()
    this.getBanner()
    this.getshowimg()

  },
  components: {
    loading
  },
  methods: {
    getBanner() {
      this.$get('index/banner')
          .then(res => {
            this.swiperList = res.data
          })
    },
    fetchData() {
      this.empty = false
      this.loading = true
      this.$get('index/newInfo', {params: this.$route.query})
          .then(res => {
            this.loading = false
            this.result = res.data ? res.data : {}
            if (!this.result.title) {
              this.empty = true
            }
          })
    },
    // getshowimg() {
    //   this.$get('index/showlishi')
    //       .then(res => {
    //         this.showimg = res.data
    //       })
    // },
    getshowimg() {
      this.$get('index/lishishow?id='+this.$route.query.id)
          .then(res => {
            this.showimg = res.data
          })
    },
  },
  filters: {
    time(dataStr) {
      if (!dataStr || dataStr == 0) {
        return '              -'
      } else {
        return moment(dataStr * 1000).format('YYYY-MM-DD HH:mm:ss')
      }
    }
  },
  watch: {
    $route() {
      this.fetchData()
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .swiper-pagination-bullet-active {
  background: #fff;
}
/deep/  img {
  max-width: 100%; /* 图片最大宽度为100% */
  height: auto;
}
.demo-image {
  display: block;
  width: 68vw;
  margin: 0 auto;
  margin-bottom:160px;
}
.slide1 {
  width: 100%;
  height: 31.2500vw;
  background: #e8e7e7;

  .swiper, .swiperSlide, .swiperSlide img {
    width: 100%;
    height: 100%;
  }
}
.block {
  //padding: 10px 0;
  text-align: center;
  //border-right: 1px solid #eff2f6;
  display: inline-block;
  width: 22vw;
  //margin-left:20px;
  //  border:1px solid #E6E6E6;

  box-sizing: border-box;
  vertical-align: top;
  margin-top:6px;

}

.company {
  //height: 6.1979vw;
  width: 100%;
  height:77px;
  width:382px;
  margin-top: 4.1146vw;
}
.el-image {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.demonstration {
  display: block;
  color: #8492a6;
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 20px;
}

//.content {
//  max-width: 70vw;
//  min-height: 100vh;
//  margin: 0 auto;
//}

.adv {
  height: 7.8125vw;
  width: 100%;
  margin: auto;
}
.team{
  height: 8vw;
  //display: block;
  //margin: 0 auto;
  margin-top: 1.8792vw;
  margin-bottom: 1.4792vw;
}
.content {
  width: 100%;
  min-height: calc(100vh - 10.8333vw - 4.1667vw);

  .view {
    width: 52.0833vw;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 4.1667vw;

    .title {
      p {
        width: 100%;
        font-size: 1.6vw;
        font-weight: bold;
        color: #333333;
        line-height: 2.1833vw;
      }

      span {
        vertical-align: bottom;
        font-size: 0.6250vw;
        font-weight: 550;
        float: right;
        color: #999999;
      }


    }

    .tuwen {
      width: 100%;
      display: flex;
      margin-top: 3.6458vw;

      img {
        width: auto;
        height: 10.4167vw;
      }

      p {
        flex: 1;
        font-size: 0.8333vw;
        font-weight: 550;
        overflow: hidden;
        color: #333333;
        line-height: 1.6667vw;
        margin-right: 3.6458vw;

        /deep/ p {
          text-indent: 2rem;
        }
      }
    }
  }
}
</style>
